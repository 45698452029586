import React from "react"
import Img from "gatsby-image"
import * as PropTypes from "prop-types"

class IndexHero extends React.Component {
  state = {
    isLoaded: false,
  }

  imageLoadCallback = () => {
    this.setState({
      isLoaded: true,
    })
  }

  render() {
    const { image } = this.props

    return (
      <div className={"hero hero--index background--dark"}>
        <div data-srgrid-row={"bottom"}>
          <div data-srgrid-col="xs-24 l-12 l-reorder-2">
            <Img fluid={image} className={`hero__image ${this.state.isLoaded ? "hero__image--animate" : ""}`} loading={"eager"}
                 fadeIn={false}
                 critital={true}
                 onLoad={() => this.imageLoadCallback()}/>
          </div>
          <div data-srgrid-col="xs-24 l-12 l-reorder-1">
            <p className={"fira-mono fira-mono--m"}>Da wo's nur Brot gibt.</p>
          </div>
        </div>
      </div>
    )
  }
}

IndexHero.propTypes = { image: PropTypes.any }

export default IndexHero