import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IndexHero from "../components/index/hero"
import ImageColumn from "../components/imageColumn"
import TextImage from "../components/textImage"
import Video from "../components/video"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      index1: file(relativePath: { eq: "index-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid,
          }
        }
      },
      index2: file(relativePath: { eq: "index-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index3: file(relativePath: { eq: "index-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index4: file(relativePath: { eq: "index-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index5: file(relativePath: { eq: "index-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index6: file(relativePath: { eq: "index-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout page={"index"}>
      <SEO title="Home"/>
      <IndexHero image={data.index1.childImageSharp.fluid}/>
      <ImageColumn source_left={data.index2.childImageSharp.fluid}
                   source_right={data.index3.childImageSharp.fluid}/>
      <TextImage headline={"Nur Brot?"}
                 source={data.index4.childImageSharp.fluid} source_position={"bottom"}>
        <div data-srgrid-row="">
          <div data-srgrid-col="xs-24 l-11 l-offset-1">
            <p>
              Das wurde ich immer wieder gefragt, nachdem ich erzählt habe,
              "dass ich mit meinem Team in der Bonner Südstadt Brot backen werde. „Ja, ganz genau!“ Mit unbehandeltem
              Mehl, mit den Händen im Teig, mit Herzblut in der Backstube und mit dem frischen Brotduft aus dem Ofen,
              der die Menschen wieder erreicht.
            </p>
          </div>
          <div data-srgrid-col="xs-24 l-11 l-offset-1">
            <p>
              Wir sind Bäcker, die einfach Bock haben. Die Macher sind. Die einfach „nur“ Brot backen. Deshalb ist es
              an der Zeit für eine neue Art Bäckerei. Und vielleicht ist es dann auch gar nicht mehr „nur“ Brot!?.
            </p>
            <br/><br/>
            <div data-srgrid-row="m-center">
              <div data-srgrid-col="xs-18" className={"_ta-left"}>
                <Link to={"/presse"} className={"h2 _td-none"}>
                  Erfahre Mehr über unsere Philosophie
                </Link>
              </div>
            </div>
          </div>
        </div>
      </TextImage>
      <ImageColumn source_left={data.index5.childImageSharp.fluid}
                   source_right={data.index6.childImageSharp.fluid}/>
      <Video type={"youtube"} text={"Max spricht auf <br/>der Be.Inside."}
             className={"background--dark background--no-padding"}/>
    </Layout>
  )
}


export default IndexPage
