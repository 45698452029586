/* eslint-disable jsx-a11y/media-has-caption */

import React, { Component } from "react"
import YouTube from "react-youtube"
import * as PropTypes from "prop-types"
import DefaultImage from "../images/video-layer.jpg"
import Img from "gatsby-image"
import AuszeitVideo from "../assets/Auszeit_Bonn.mp4"
import LokalzeitVideo from "../assets/Lokalzeit_Bonn.mp4"

class Video extends Component {
  state = {
    showPlayer: false,
  }

  toggleVideoPlayer = () => {
    this.setState({
      showPlayer: !this.state.showPlayer,
    })
  }

  render() {
    const { type, text, text_position, text_padding, videoImage, videoFile, videoSize, className } = this.props
    const opts = {
      height: "390",
      width: "640",
      playerVars: {
        autoplay: 1,
      },
    }

    return (
      <div className={`video video--${type} ${className}`}>
        <>
          {(text && text_position === "top") && (
            <div data-srgrid-row="end">
              <div data-srgrid-col="xs-24">
                <div className={`video__text ${text_padding === false && "video__text--no-padding"}`}>
                  <p className={"fira-mono fira-mono--xs _ta-right"} dangerouslySetInnerHTML={{ __html: text }}/>
                </div>
              </div>
            </div>
          )}
        </>
        {type === "youtube" ? (
          <div className={"video__overlay"}>
            {this.state.showPlayer ? (
              <YouTube
                videoId="WIMBaCxwsiw"
                opts={opts}
              />
            ) : (
              <>
                <div className={`overlay__triangle ${videoSize ? "overlay__triangle--small" : ""}`}
                     onClick={() => this.toggleVideoPlayer()} onKeyPress={() => this.toggleVideoPlayer()}
                     role="button"
                     tabIndex="0"/>
                {videoImage ? (
                  <Img fluid={videoImage}/>
                ) : (
                  <img src={DefaultImage} alt=""/>
                )}
              </>
            )}
          </div>
        ) : type === "none" ? (
          <div className={"video__overlay"}>
            <div className={`overlay__triangle ${videoSize ? "overlay__triangle--small" : ""}`}
                 onClick={() => this.toggleVideoPlayer()}
                 role="button"
                 tabIndex="0"
                 onKeyPress={() => this.toggleVideoPlayer()}/>
            <Img fluid={videoImage}/>
          </div>
        ) : (
          <div className={"video__overlay"}>
            {this.state.showPlayer ? (
              <video controls autoPlay={true}>
                <source src={videoFile === "Lokalzeit" ? LokalzeitVideo : videoFile === "Auszeit" ? AuszeitVideo : null}
                        type="video/mp4"/>
              </video>
            ) : (
              <>
                <div className={`overlay__triangle ${videoSize ? "overlay__triangle--small" : ""}`}
                     role="button"
                     tabIndex="0"/>
                <Img fluid={videoImage}/>
              </>
            )}
          </div>
        )}
        <>
          {(text && text_position === "bottom") && (
            <div data-srgrid-row="start">
              <div data-srgrid-col="xs-24">
                <div className={`video__text ${text_padding === false && "video__text--no-padding"}`}>
                  <p className={"fira-mono fira-mono--xs _ta-right"} dangerouslySetInnerHTML={{ __html: text }}/>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    )
  }
}

Video.propTypes = { type: PropTypes.any }

export default Video